import styled from 'styled-components';
import { breakpoints } from 'src/styles';

export default {
  Content: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  `,
  TextContainer: styled.div`
    display: flex;
    flex-direction: column;
    color: white;
  `,
  Description: styled.div`
    max-width: 530px;
    margin-top: 15px;
  `,
  ButtonContainer: styled.div`
    margin-top: 20px;

    @media screen and (min-width: ${breakpoints.sm.max}) {
      margin: 0;
    }
  `,
};
