import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { FluidImage, FontIcon } from 'src/modules/image';
import { convertMDToInnerHTML } from 'src/modules/text';
import { colors } from 'src/styles';
import { ProgramSettingsContext } from 'src/templates/program/program.Context';
import { hasDatePassed } from 'src/modules/date';
import S from './program.HeaderSection.Styled';

const HeaderSection = ({ tag, description, location, background, enablerImage }) => {
  const { type, startDate, endDate } = useContext(ProgramSettingsContext);

  const isActive = hasDatePassed(endDate);
  // Get Into Freelance type should be displayed as Get Into.
  const programName = type === 'Get Into Freelance' ? 'Get Into' : type;
  const showWordBy = type !== 'Ready To Work';

  return (
    <S.Section withPadding backgroundColor={colors.background.lightblue}>
      <S.BackgroundImage>
        <FluidImage style={{ height: '100%' }} imgStyle={{ objectFit: 'cover' }} image={background} />
      </S.BackgroundImage>
      <S.Content>
        <S.ProgramHead>
          <S.Program>{programName}</S.Program>
          <S.ProgramTag as="h2">{tag}</S.ProgramTag>
          {enablerImage && (
            <S.LogoContainer>
              {showWordBy && <div>by</div>}
              <S.EnablerContainer>
                <FluidImage image={enablerImage} />
              </S.EnablerContainer>
            </S.LogoContainer>
          )}
          {!isActive && <S.FinishedLabel>ΟΛΟΚΛΗΡΩΜΕΝΟ</S.FinishedLabel>}
        </S.ProgramHead>
        <S.Description dangerouslySetInnerHTML={convertMDToInnerHTML(description)} />
        <S.ProgramFooter>
          <S.Dates>
            <FontIcon size={20} name="calendar" color="white" /> Από {startDate.format('DD/MM')} έως{' '}
            {endDate.format('DD/MM')}
          </S.Dates>
          <S.Location>
            <FontIcon size={20} name="location" color="white" />
            <div>{location}</div>
          </S.Location>
        </S.ProgramFooter>
      </S.Content>
    </S.Section>
  );
};

HeaderSection.propTypes = {
  tag: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  background: PropTypes.shape({
    childImageSharp: PropTypes.object,
  }).isRequired,
  enablerImage: PropTypes.shape({
    childImageSharp: PropTypes.object,
  }),
};

HeaderSection.defaultProps = {
  enablerImage: null,
};

export default HeaderSection;
