import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Button } from 'src/modules/input';
import { FluidImage } from 'src/modules/image';
import { Section, PageWrap } from 'src/modules/layout';
import { hasDatePassed } from 'src/modules/date';
import { ProgramSettingsContext } from './program.Context';
import S from './program.CustomSection.Styled';

const InfoCard = ({ title, bullets }) => (
  <S.InfoCard>
    <S.InfoCardHeader>
      <S.InfoCardTitle>{`${title}:`}</S.InfoCardTitle>
    </S.InfoCardHeader>
    <S.InfoCardBullets>
      {bullets && bullets.map(bullet => <S.InfoCardBullet key={bullet.text}>{bullet.text}</S.InfoCardBullet>)}
    </S.InfoCardBullets>
  </S.InfoCard>
);

InfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  bullets: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string })).isRequired,
};

const InfoSection = ({ heading, requirements }) => {
  return (
    <Section withPadding>
      <PageWrap>
        <S.Heading>{heading}</S.Heading>
        <S.InfoCards>
          {requirements &&
            requirements.map(requirementCategory => (
              <InfoCard
                key={requirementCategory.title}
                title={requirementCategory.title}
                icon={requirementCategory.icon}
                bullets={requirementCategory.bullets}
              />
            ))}
        </S.InfoCards>
      </PageWrap>
    </Section>
  );
};

InfoSection.propTypes = {
  heading: PropTypes.string.isRequired,
  requirements: PropTypes.arrayOf(
    PropTypes.shape({
      title: InfoCard.title,
      bullets: InfoCard.bullets,
    }),
  ).isRequired,
};

const CompanyLogosSection = ({ heading, logos, emptyMessage, button, buttonLink }) => {
  const { endDate } = useContext(ProgramSettingsContext);
  const isActive = hasDatePassed(endDate);

  return (
    <Section withPadding>
      <PageWrap>
        <S.Heading>{heading}</S.Heading>
        {logos.length === 0 && <S.EmptyMessage>{emptyMessage}</S.EmptyMessage>}
        {logos.length !== 0 && (
          <>
            <S.Logos>
              {logos.map((logo, index) => (
                /* eslint-disable-next-line */
                <S.FlexItem key={index}>
                  <S.LogoContainer>
                    <FluidImage
                      image={logo.image}
                      style={{ height: '100%', width: '100%' }}
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  </S.LogoContainer>
                </S.FlexItem>
              ))}
            </S.Logos>
            <S.CustomSecionButtonContainer>
              <Button secondary disabled={!isActive} href={buttonLink}>
                {button}
              </Button>
            </S.CustomSecionButtonContainer>
          </>
        )}
      </PageWrap>
    </Section>
  );
};

CompanyLogosSection.propTypes = {
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.object,
    }),
  ),
  heading: PropTypes.string.isRequired,
  emptyMessage: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
};

CompanyLogosSection.defaultProps = {
  logos: [],
};

const CustomSection = ({ data }) => {
  // custom section data is an array, in order to support netlify cms variable objects
  // customSectionData should have max 1 item
  let sectionData = {};
  if (_.isArray(data) && data.length > 0) {
    [sectionData] = data;
  } else {
    return (
      <Section withPadding>
        <PageWrap>
          <S.Heading>Custom section doesn&apos;t support preview</S.Heading>
        </PageWrap>
      </Section>
    );
  }

  if (sectionData.type === 'default') {
    return <InfoSection {...sectionData} />;
  }

  if (sectionData.type === 'live') {
    return <CompanyLogosSection {...sectionData} />;
  }

  return null;
};

CustomSection.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CustomSection;
