import styled from 'styled-components';

import { Heading } from 'src/modules/text';
import { colors, breakpoints } from 'src/styles';

export default {
  Heading: styled(Heading)`
    text-align: center;
  `,
  InfoCards: styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    @media screen and (min-width: ${breakpoints.md.max}) {
      flex-wrap: nowrap;
    }
  `,
  InfoCard: styled.div`
    width: 460px;
    border: 1px solid ${colors.main.grey1};
    border-left: 7px solid ${colors.main.orange};
    border-radius: 7px;
    padding: 25px;
    margin: 10px 0px;

    @media screen and (min-width: ${breakpoints.xs.max}) {
      margin: 25px;
    }
  `,
  InfoCardHeader: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  `,
  InfoCardIcon: styled.div`
    margin-right: 15px;
  `,
  InfoCardTitle: styled.div`
    font-size: 23px;
    font-weight: bold;
    color: ${colors.main.blue};
  `,
  InfoCardBullets: styled.ul`
    padding-left: 20px;
    margin-bottom: 0px;
  `,
  InfoCardBullet: styled.li`
    padding-top: 7px;
    &:not(:last-child) {
      padding-bottom: 7px;
    }
  `,
  Logos: styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 70px;
    margin-bottom: 70px;
    justify-content: center;
  `,
  FlexItem: styled.div`
    display: flex;
    flex-basis: 50%;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: ${breakpoints.sm.min}) {
      flex-basis: 33.3%;
    }
    @media screen and (min-width: ${breakpoints.md.max}) {
      flex-basis: 20%;
    }
  `,
  LogoContainer: styled.div`
    width: 120px;
    height: 120px;
    margin: 15px 0px;
  `,
  CustomSecionButtonContainer: styled.div`
    display: flex;
    justify-content: center;
  `,
  EmptyMessage: styled.div`
    margin-top: 70px;
    text-align: center;
  `,
};
