import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { convertMDToInnerHTML } from 'src/modules/text';
import { Section, PageWrap } from 'src/modules/layout';
import { Button } from 'src/modules/input';
import { hasDatePassed } from 'src/modules/date';
import { colors } from 'src/styles';
import { ProgramSettingsContext } from './program.Context';
import S from './program.ApplyTopSection.Styled';

const ApplyTopSection = ({ description, button, buttonLink }) => {
  const { endDate } = useContext(ProgramSettingsContext);
  const isActive = hasDatePassed(endDate);

  return (
    <Section withPadding backgroundColor={colors.background.lightblue}>
      <PageWrap>
        <S.Content>
          <S.Description dangerouslySetInnerHTML={convertMDToInnerHTML(description)}></S.Description>
          <S.ButtonContainer>
            <Button disabled={!isActive} href={buttonLink}>
              {button}
            </Button>
          </S.ButtonContainer>
        </S.Content>
      </PageWrap>
    </Section>
  );
};

ApplyTopSection.propTypes = {
  description: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
};

export default ApplyTopSection;
