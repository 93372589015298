import React from 'react';
import PropTypes from 'prop-types';

import { Layout } from 'src/modules/layout';
import { parseDate } from 'src/modules/date';

import HeaderSection from './program.HeaderSection';
import ApplyTopSection from './program.ApplyTopSection';
import ApplyBottomSection from './program.ApplyBottomSection';
import CustomSection from './program.CustomSection';
import BulletSection from './program.BulletSection';
import { ProgramSettingsContext } from './program.Context';

const ProgramPageTemplate = ({
  title,
  description,
  visible,
  type,
  startDate,
  endDate,
  headerSectionData,
  applyTopSectionData,
  customSectionData,
  bulletSectionData,
  applyBottomSectionData,
}) => {
  const settings = { visible, type, startDate: parseDate(startDate), endDate: parseDate(endDate) };

  return (
    <Layout headOptions={{ title, description }} headerOptions={{ theme: 'light' }}>
      <ProgramSettingsContext.Provider value={settings}>
        <HeaderSection {...headerSectionData} />
        <ApplyTopSection {...applyTopSectionData} />
        <CustomSection data={customSectionData} />
        <BulletSection {...bulletSectionData} />
        <ApplyBottomSection {...applyBottomSectionData} />
      </ProgramSettingsContext.Provider>
    </Layout>
  );
};

ProgramPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  headerSectionData: PropTypes.shape(HeaderSection.propTypes).isRequired,
  applyTopSectionData: PropTypes.shape(ApplyTopSection.propTypes).isRequired,
  customSectionData: PropTypes.arrayOf(PropTypes.object).isRequired,
  bulletSectionData: PropTypes.shape(BulletSection.propTypes).isRequired,
  applyBottomSectionData: PropTypes.shape(ApplyTopSection.propTypes).isRequired,
};

export default ProgramPageTemplate;
