import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import ProgramPageTemplate from './program/program.Template';

const ProgramPage = ({ data }) => {
  const {
    title,
    visible,
    type,
    description,
    startDate,
    endDate,
    headerSectionData,
    applyTopSectionData,
    customSectionData,
    bulletSectionData,
    applyBottomSectionData,
  } = data.markdownRemark.frontmatter;
  return (
    <ProgramPageTemplate
      title={title}
      visible={visible}
      type={type}
      description={description}
      startDate={startDate}
      endDate={endDate}
      headerSectionData={headerSectionData}
      applyTopSectionData={applyTopSectionData}
      customSectionData={customSectionData}
      bulletSectionData={bulletSectionData}
      applyBottomSectionData={applyBottomSectionData}
    />
  );
};

ProgramPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object.isRequired,
    }),
  }).isRequired,
};

export default ProgramPage;

export const pageQuery = graphql`
  query ProgramPageTemplate($id: String) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        visible
        type
        description
        startDate
        endDate
        headerSectionData {
          tag
          description
          location
          background {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          enablerImage {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        applyTopSectionData {
          description
          button
          buttonLink
        }
        applyBottomSectionData {
          heading
          description
          button
          buttonLink
        }
        bulletSectionData {
          heading
          bullets {
            text
          }
        }
        customSectionData {
          heading
          type
          requirements {
            title
            bullets {
              text
            }
            icon {
              width
              image {
                childImageSharp {
                  fluid(maxWidth: 100) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
          logos {
            image {
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          emptyMessage
          button
          buttonLink
        }
      }
    }
  }
`;
