import React from 'react';
import PropTypes from 'prop-types';

import { colors } from 'src/styles';
import { Section, PageWrap } from 'src/modules/layout';
import bulletIcon from 'src/assets/images/check-mark.svg';
import S from './program.BulletSection.Styled';

const BulletItem = ({ text }) => (
  <S.BulletItem>
    <S.BulletIcon src={bulletIcon} />
    <S.BulletText>{text}</S.BulletText>
  </S.BulletItem>
);

BulletItem.propTypes = {
  text: PropTypes.string.isRequired,
};

const BulletSection = ({ heading, bullets }) => {
  return (
    <Section withPadding backgroundColor={colors.background.lightblue}>
      <PageWrap>
        <S.Heading>{heading}</S.Heading>
        <S.BulletsListContainer>
          <S.BulletList>
            {bullets && bullets.map(bullet => <BulletItem key={bullet.text} text={bullet.text} />)}
          </S.BulletList>
        </S.BulletsListContainer>
      </PageWrap>
    </Section>
  );
};

BulletSection.propTypes = {
  heading: PropTypes.string.isRequired,
  bullets: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string })).isRequired,
};

export default BulletSection;
