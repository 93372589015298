import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Heading } from 'src/modules/text';
import { Section, PageWrap } from 'src/modules/layout';
import { Button } from 'src/modules/input';
import { hasDatePassed } from 'src/modules/date';
import { colors } from 'src/styles';
import { ProgramSettingsContext } from './program.Context';
import S from './program.ApplyBottomSection.Styled';

const ApplyBottomSection = ({ heading, description, button, buttonLink }) => {
  const { endDate } = useContext(ProgramSettingsContext);
  const isActive = hasDatePassed(endDate);

  return (
    <Section withPadding backgroundColor={colors.main.blue}>
      <PageWrap>
        <S.Content>
          <S.TextContainer>
            <Heading>{heading}</Heading>
            <S.Description>{description}</S.Description>
          </S.TextContainer>
          <S.ButtonContainer>
            <Button href={buttonLink} disabled={!isActive}>
              {button}
            </Button>
          </S.ButtonContainer>
        </S.Content>
      </PageWrap>
    </Section>
  );
};

ApplyBottomSection.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
};

export default ApplyBottomSection;
