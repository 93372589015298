import styled from 'styled-components';

import { Heading } from 'src/modules/text';
import { layoutStyles, Section } from 'src/modules/layout';
import { breakpoints, colors } from 'src/styles';

export default {
  BackgroundImage: styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
    background: ${colors.main.blue};
  `,
  Section: styled(Section)`
    position: relative;
    padding: 0px;
  `,
  Content: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${layoutStyles.pageWrap};
    color: white;
    max-width: 620px;
    padding: 150px 20px 50px 20px;

    @media only screen and (min-width: ${breakpoints.xs.max}) {
      padding-top: 200px;
      padding-bottom: 200px;
    }
  `,
  ProgramHead: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
  `,
  Program: styled(Heading)`
    font-weight: bold;
    font-size: 28px;
  `,
  ProgramTag: styled(Heading)`
    text-align: center;
    font-size: 65px;
    font-weight: 100;
    line-height: 1;
    margin: 0;
    @media only screen and (min-width: ${breakpoints.xs.max}) {
      font-size: 110px;
    }
  `,
  LogoContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Description: styled.div`
    padding-bottom: 15px;
    border-bottom: 1px solid white;
    text-align: center;
  `,
  ProgramFooter: styled.div`
    display: flex;
    padding: 15px;
    flex-wrap: wrap;
  `,
  Dates: styled.div`
    font-weight: bold;
    margin-right: 15px;
    padding: 3px 0px;
  `,
  Location: styled.div`
    display: flex;
    font-weight: bold;
    padding: 3px 0px;
    align-items: center;
  `,
  EnablerContainer: styled.div`
    width: 150px;
    margin-left: 15px;
  `,
  FinishedLabel: styled.div`
    margin-top: 60px;
    background: white;
    padding: 10px 25px;
    border-radius: 7px;
    color: #4977b3;
    font-weight: bold;
    font-size: 14px;
  `,
};
