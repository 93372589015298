import styled from 'styled-components';

import { Heading } from 'src/modules/text';
import { breakpoints } from 'src/styles';

export default {
  Heading: styled(Heading)`
    text-align: center;
    margin-bottom: 50px;
    font-size: 28px;
  `,
  BulletsListContainer: styled.div`
    display: flex;
    justify-content: center;
  `,
  BulletList: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;

    @media only screen and (min-width: ${breakpoints.sm.max}) {
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  `,
  BulletItem: styled.div`
    display: flex;
    padding: 15px;
    box-sizing: border-box;
    align-items: center;

    @media screen and (min-width: ${breakpoints.xs.max}) {
      flex-basis: 50%;
    }
  `,
  BulletIcon: styled.img`
    margin-right: 10px;
  `,
  BulletText: styled.div`
    max-width: 420px;
    @media only screen and (min-width: ${breakpoints.sm.max}) {
      max-width: 360px;
    }
    @media only screen and (min-width: ${breakpoints.md.max}) {
      max-width: 420px;
    }
  `,
};
